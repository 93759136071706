.rc-mentions > textarea {
  min-height: 50px;
  max-height: 300px;
  max-width: 100%;
  width: 100%;
  padding: 10px;
  background: #fff;
  font-size: 15px;
  border-radius: 15px 15px 0 0;
  border: none;
  outline: none;
  resize: none;
}

.rc-mentions .rc-mentions-measure {
  position: absolute;
  pointer-events: none;
  color: transparent;
  z-index: -1;
}

.rc-mentions-dropdown {
  top: 710px;
  width: 245px;
  background-color: white;
  border-radius: 10px 10px 10px 0px;
  box-shadow: 0px 0px 10px 1px #c0c0c0;
}

.rc-mentions-dropdown-menu-item-active {
  background: #e9ebef;
}
